import React, { useEffect, useState } from "react";
import ItemGallery from "ecommerce-commons-ui/core/ItemGallery";
import { dataSourceV2 } from "commons-ui/core/DataSource";
import Item from "./Item";
import "./style.styl";

export default function UpsellingV2(props) {
  const {
    filters,
    categoryId,
    locationId,
    type,
    location_widget,
    ecommerceStoreId,
    ecommerceStoreGroupId,
    size,
    exclude,
    apiPath,
  } = props;
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(true);

  const request = (categoryId) => {
    const query = {
      size: size || 4,
      sort: [],
      _source: ["search_result_data"],
      query: {
        bool: {
          must: [],
          filter: [
            { "term":  { "search_data.ecommerce_store_id": ecommerceStoreId }}
          ],
          must_not: [{ term: { "hidden.id_list": "default" } }],
        },
      },
    };

    if (exclude) {
        query["query"]["bool"]["must_not"].push({
          terms: { "search_data.ecommerce_product_id": exclude },
        });
    }

    if (categoryId) {
      query["query"]["bool"]["filter"].push({
        term: { "category.id_list": categoryId },
      });
    }

    if (locationId) {
        query["query"]["bool"]["must_not"].push({"term":{"inventory_hidden.id_list": locationId}});
    }

    dataSourceV2(
      `ecommerce_product_view_list?is_search=true&postprocess=${locationId || "default"}&query=${JSON.stringify(
        query
      )}`,
      { url: apiPath }
    ).then((v) => {
      if (!v.message && v.item_list) {
        const itemList = v.item_list.map((data) => {
          const category_id_dict = data.category_id_dict || {};
          const version = data.version || {};

          return {
            ...version,
            category_id_dict,
            attribute_set_id: data.attribute_set_id,
            ecommerce_product_id: data.ecommerce_product_id,
          };
        });
        setItemList(itemList);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    request(categoryId);
}, []);

  if (!loading && itemList.length === 0) {
    return null;
  }

  return (
    <div id="sales-section">
      <div className="">
        <ItemGallery
          {...props}
          loading={loading}
          negativeShift={24}
          itemComponent={(props) => {
            return (
              <div className="item u-sizeFullHeight u-padding5">
                <Item
                  item={props.item}
                  {...(props.props || {})}
                  itemId="ecommerce_product_id"
                  modelName="ecommerce_product"
                  loading={loading}
                />
              </div>
            );
          }}
          loadingItemsCount={size || 10}
          items={itemList}
        />
      </div>
    </div>
  );
}
